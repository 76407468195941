<template>
  <div>
    <div class="iss-main">
      <div class="center-box">
        <a-form ref="formRef" :model="dynamicValidateForm">
          <a-card title="基础信息">
            <a-row :gutter="20">
              <a-col :span="12">
                <a-form-item name="name" label="模型名称" :rules="required">
                  <a-input
                    v-model:value="dynamicValidateForm.name"
                    :disable="isWatch"
                    style="width: 70%; margin-left: 10px"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item name="leadPools" label="应用线索池名称">
                  <a-input
                    v-model:value="dynamicValidateForm.leadPools"
                    :disabled="true"
                    style="width: 70%; margin-left: 10px"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-card>

          <a-card title="属性满足" v-if="!isWatch || propertyCount > 0">
            <table class="condition-table">
              <tr
                :key="index"
                v-for="(condition, index) in dynamicValidateForm.groupRuleList"
              >
                <template
                  v-if="
                    condition.type === 'property' || condition.type === 'tag'
                  "
                >
                  <td style="width: 16%">
                    <a-form-item
                      :name="['groupRuleList', index, 'col']"
                      :rules="required"
                    >
                      <a-select
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                        v-model:value="condition.col"
                        :disabled="isWatch"
                        placeholder="请选择属性"
                        @change="handleChangeProperty($event, condition)"
                      >
                        <a-select-option
                          v-for="p in propertyList"
                          :key="p.colCode"
                          :value="p.colCode"
                        >
                          {{ p.colName }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </td>
                  <td style="width: 10%">
                    <a-form-item
                      :name="['groupRuleList', index, 'operator']"
                      :rules="required"
                    >
                      <a-select
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                        v-model:value="condition.operator"
                        :disabled="isWatch"
                        placeholder="请选择操作符"
                      >
                        <a-select-option
                          v-for="o in condition.operatorList"
                          :key="o.symbol"
                          :value="o.symbol"
                        >
                          {{ o.symbolName }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </td>
                  <td style="width: 40%">
                    <a-form-item
                      :name="['groupRuleList', index, 'value']"
                      :rules="required"
                    >
                      <a-textarea
                        v-if="
                          ['tag', '标签'].includes(condition.col) && isWatch
                        "
                        disabled
                        :value="condition.valueName"
                      />
                      <a-tree-select
                        v-else-if="
                          ['tag', '标签'].includes(condition.col) && !isWatch
                        "
                        v-model:value="condition.value"
                        placeholder="请选择"
                        allowClear
                        :tokenSeparators="[',']"
                        :tree-data="tagTreeList"
                        multiple
                        treeCheckable
                        :disabled="isWatch"
                        :maxTagCount="2"
                        :show-checked-strategy="SHOW_CHILD"
                      >
                      </a-tree-select>
                      <a-select
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                        v-else-if="['gender'].includes(condition.col)"
                        v-model:value="condition.value"
                        :disabled="isWatch"
                        :options="genderList"
                        placeholder="请选择"
                      />
                      <a-select
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                        v-else-if="['receive_consult'].includes(condition.col)"
                        v-model:value="condition.value"
                        :disabled="isWatch"
                        :options="receiveConsultList"
                        placeholder="请选择"
                      />
                      <!--                      <a-select-->
                      <!--                        v-model:value="condition.value"-->
                      <!--                        :disabled="isWatch"-->
                      <!--                        placeholder="请选择行业"-->
                      <!--                        v-else-if="-->
                      <!--                          ['industry_lv1_code'].includes(condition.col)-->
                      <!--                        "-->
                      <!--                      >-->
                      <!--                        <a-select-option-->
                      <!--                          v-for="item in industryList"-->
                      <!--                          :key="item.id"-->
                      <!--                          :value="item.itemCode"-->
                      <!--                          :item="item"-->
                      <!--                        >-->
                      <!--                          {{ item.itemNameCn }}-->
                      <!--                        </a-select-option>-->
                      <!--                      </a-select>-->
                      <!--                          包含有 公司和职位 -->
                      <a-textarea
                        v-else-if="
                          ['position', 'company'].includes(condition.col)
                        "
                        v-model:value="condition.value"
                        auto-size
                        :disabled="isWatch"
                        placeholder="请输入..."
                      />
                      <a-select
                        v-else-if="['province', 'city'].includes(condition.col)"
                        v-model:value="condition.value"
                        show-search
                        :disabled="isWatch"
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in list[condition.col]"
                          :key="item.id"
                          :value="item.itemCode"
                          :item="item"
                        >
                          {{ item.itemNameCn }}
                        </a-select-option>
                      </a-select>
                      <a-cascader
                        style="background: #f2f5fa"
                        v-else-if="['area'].includes(condition.col)"
                        v-model:value="condition.value"
                        placeholder="请选择"
                        allowClear
                        change-on-select
                        :options="cityList"
                        :fieldNames="{
                          children: 'children',
                          label: 'itemNameCn',
                          value: 'itemCode',
                          key: 'id',
                        }"
                      >
                      </a-cascader>
                      <a-input
                        v-else
                        :disabled="isWatch"
                        allowClear
                        v-model:value="condition.value"
                        placeholder="请输入..."
                      />
                    </a-form-item>
                  </td>
                  <td style="width: 17%">
                    <a-form-item
                      class="hideRequiredMark"
                      :name="['groupRuleList', index, 'score']"
                      :rules="{ ...required }"
                      label="设为"
                      :validateTrigger="['blur', 'change']"
                    >
                      <a-input-number
                        v-model:value="condition.score"
                        placeholder=""
                        :disabled="isWatch"
                        style="width: 100%"
                        :max="100"
                        :min="1"
                        @change="getTotal"
                      />
                    </a-form-item>
                  </td>
                  <td style="width: 3%">
                    <a-form-item> 分 </a-form-item>
                  </td>
                  <td style="width: 4%">
                    <a-form-item>
                      <a
                        class="remove-btn"
                        @click="removeRule(index)"
                        v-if="!isWatch"
                      />
                    </a-form-item>
                  </td>
                </template>
              </tr>
              <tr v-if="!isWatch">
                <a class="add-btn" @click="addGroupRule('property', index)" />
              </tr>
            </table>
          </a-card>
          <a-card
            title="行为满足"
            :bordered="false"
            v-if="!isWatch || behaviorCount > 0"
          >
            <template v-slot:extra>
              <a-form-item name="timeValue" :rules="required">
                <a-range-picker
                  :placeholder="['起始月份', '结束月份']"
                  value-format="YYYY-MM"
                  format="YYYY-MM"
                  :allowClear="false"
                  :value="dynamicValidateForm.timeValue"
                  :mode="mode"
                  :disabled="isWatch"
                  @change="handleChange"
                  @panelChange="handlePanelChange"
                />
              </a-form-item>
            </template>
            <table class="condition-table">
              <tr
                :key="index"
                v-for="(condition, index) in dynamicValidateForm.groupRuleList"
              >
                <template v-if="condition.type === 'behavior'">
                  <td style="width: 16%">
                    <a-form-item
                      :name="['groupRuleList', index, 'col']"
                      :rules="required"
                    >
                      <a-select
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                        v-model:value="condition.col"
                        :disabled="isWatch"
                        placeholder="请选择行为"
                        @change="handleChangeAction($event, condition)"
                      >
                        <a-select-option
                          v-for="ap in actionPropertyList"
                          :key="ap.colCode"
                          :value="ap.colCode"
                        >
                          {{ ap.colName }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </td>
                  <td style="width: 10%">
                    <a-form-item
                      :name="['groupRuleList', index, 'action']"
                      :rules="required"
                    >
                      <a-select
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                        v-model:value="condition.action"
                        :disabled="isWatch"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="o in condition.actionList"
                          :key="o.value"
                          :value="o.value"
                        >
                          {{ o.name + `${o.unit ? '（' + o.unit + '）' : ''}` }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </td>
                  <td style="width: 10%">
                    <a-form-item
                      :name="['groupRuleList', index, 'operator']"
                      :rules="required"
                    >
                      <a-select
                        :getPopupContainer="
                          triggerNode => triggerNode.parentNode
                        "
                        v-model:value="condition.operator"
                        :disabled="isWatch"
                        placeholder="请选择操作符"
                      >
                        <a-select-option
                          v-for="o in operatorListMap[condition.dataType]"
                          :key="o.symbol"
                          :value="o.symbol"
                        >
                          {{ o.symbolName }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </td>
                  <!--                  <td-->
                  <!--                    :style="{-->
                  <!--                      width: condition.operator === '()' ? '15%' : '40%',-->
                  <!--                    }"-->
                  <!--                  >-->
                  <!--                    <a-form-item-->
                  <!--                      :name="['groupRuleList', index, 'value']"-->
                  <!--                      :rules="required"-->
                  <!--                    >-->
                  <!--                      <a-input-->
                  <!--                        v-model:value="condition.value"-->
                  <!--                        placeholder="请输入..."-->
                  <!--                        style="width: 100%"-->
                  <!--                        :disabled="isWatch"-->
                  <!--                    /></a-form-item>-->
                  <!--                  </td>-->
                  <!--                  <td style="width: 2%" v-if="condition.operator === '()'">-->
                  <!--                    <a-form-item>-->
                  <!--                      <span> - </span>-->
                  <!--                    </a-form-item>-->
                  <!--                  </td>-->
                  <td style="width: 40%">
                    <div style="display: flex">
                      <a-form-item
                        :style="{
                          width: condition.operator === '[]' ? '48%' : '100%',
                        }"
                        :name="['groupRuleList', index, 'value']"
                        :rules="{
                          required: true,
                          message: '不能为空',
                        }"
                      >
                        <a-input-number
                          :min="1"
                          v-model:value="condition.value"
                          placeholder="请输入正整数"
                          style="width: 100%"
                          :disabled="isWatch"
                      /></a-form-item>

                      <a-form-item
                        style="width: 2%"
                        v-if="condition.operator === '[]'"
                      >
                        <span> - </span>
                      </a-form-item>
                      <a-form-item
                        v-if="condition.operator === '[]'"
                        style="width: 48%"
                        :name="['groupRuleList', index, 'secondValue']"
                        :rules="{
                          required: true,
                          message: '不能为空',
                        }"
                      >
                        <a-input-number
                          :min="1"
                          v-model:value="condition.secondValue"
                          placeholder="请输入正整数"
                          style="width: 100%"
                          :disabled="isWatch"
                        />
                      </a-form-item>
                    </div>
                  </td>
                  <td style="width: 17%">
                    <a-form-item
                      class="hideRequiredMark"
                      :name="['groupRuleList', index, 'score']"
                      :rules="{ ...required }"
                      label="设为"
                      :validateTrigger="['blur', 'change']"
                    >
                      <a-input-number
                        v-model:value="condition.score"
                        placeholder=""
                        :disabled="isWatch"
                        style="width: 100%"
                        :max="100"
                        :min="1"
                        @change="getTotal"
                      />
                    </a-form-item>
                  </td>
                  <td style="width: 3%">
                    <a-form-item> 分 </a-form-item>
                  </td>
                  <td style="width: 4%">
                    <a-form-item>
                      <a
                        class="remove-btn"
                        @click="removeRule(index)"
                        v-if="!isWatch"
                      />
                    </a-form-item>
                  </td>
                </template>
              </tr>
              <tr v-if="!isWatch">
                <a class="add-btn" @click="addGroupRule('behavior', index)" />
              </tr>
            </table>
          </a-card>
        </a-form>
      </div>
      <a-card :bordered="false">
        <!--          <div>-->
        <!--            <table class="condition-table">-->
        <!--              <tr>-->
        <!--                <td style="width: 73%">总分最大值为</td>-->
        <!--                <td style="width: 27%">-->
        <!--                  <a-input-number-->
        <!--                    v-model:value="cluedetail.totalScoreMax"-->
        <!--                    placeholder="请输入"-->
        <!--                    style="width: 86px; margin: 0 10px 0 38px"-->
        <!--                    disabled-->
        <!--                    v-if="!isWatch"-->
        <!--                  />-->
        <!--                  <span class="pl-40" v-if="isWatch">{{-->
        <!--                    cluedetail.totalScoreMax-->
        <!--                  }}</span>-->
        <!--                  <span>分</span>-->
        <!--                </td>-->
        <!--                <td style="width: 15%"></td>-->
        <!--              </tr>-->
        <!--              <tr>-->
        <!--                <td style="width: 65%">进入该线索池最小值为</td>-->
        <!--                <td style="width: 20%">-->
        <!--                  <a-input-number-->
        <!--                    v-model:value="cluedetail.intoPoolMin"-->
        <!--                    placeholder="请输入"-->
        <!--                    :min="1"-->
        <!--                    style="width: 86px; margin: 0 10px 0 38px"-->
        <!--                    v-if="!isWatch"-->
        <!--                  />-->
        <!--                  <span class="pl-40" v-if="isWatch">{{-->
        <!--                    cluedetail.intoPoolMin-->
        <!--                  }}</span>-->
        <!--                  <span>分</span>-->
        <!--                </td>-->
        <!--                <td style="width: 15%"></td>-->
        <!--              </tr>-->
        <!--            </table>-->
        <!--          </div>-->
        <div class="ta-right">
          <a-space>
            <a-button type="primary" @click="save" v-if="!isWatch">
              保存
            </a-button>
            <a-button @click="$router.go(-1)"> 返回 </a-button>
          </a-space>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import {
  Card,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Button,
  Space,
  DatePicker,
  message,
  Form,
  TreeSelect,
  Cascader,
} from 'ant-design-vue';
import { useRoute, useRouter } from 'vue-router';
import cluescoreApi from '@/api/cluescore';
import dictionaryApi from '@/api/dictionary';
import tagApi from '@/api/tag';
import { reactive, toRefs, ref } from 'vue';
import moment from 'moment';

export default {
  name: 'detail',
  components: {
    ACard: Card,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    AForm: Form,
    AFormItem: Form.Item,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    ASpace: Space,
    ARangePicker: DatePicker.RangePicker,
    ATreeSelect: TreeSelect,
    ATextarea: Input.TextArea,
    ACascader: Cascader,
  },
  setup() {
    const SHOW_CHILD = TreeSelect.SHOW_CHILD;
    const state = reactive({
      attribute: {
        name: '',
        operator: '',
        list: [],
        score: '',
      },
      propertyList: [],
      industryList: [],
      genderList: [
        { value: '0', label: '男' },
        { value: '1', label: '女' },
      ],
      receiveConsultList: [
        { value: 'ture', label: '接受' },
        { value: 'false', label: '不接受' },
      ],
      actionPropertyList: [],
      behaviorCount: 0,
      propertyCount: 0,
      behaviorAllList: [
        { label: '总次数', value: 'count' },
        { label: '总时长', value: 'total_time' },
      ],
      behaviorLiveList: [{ label: '总时长', value: 'total_time' }],
      behaviorCountList: [{ label: '总次数', value: 'count' }],
      operatorList: [
        { label: '等于', value: '=' },
        { label: '不等于', value: '!=' },
        { label: '大于', value: '>' },
        { label: '小于', value: '<' },
        { label: '介于', value: '[]' },
      ],
      operatorListMap: {},

      behaviorList: [{ label: '总时长', value: 'time' }],
      cluedetail: {
        id: '',
        intoPoolMin: 60,
        leadPools: '',
        name: '',
        totalScoreMax: 0,
        timeValue: [],
      },
      TimePick: [moment(new Date()), moment(new Date())],
      tagTreeList: [],
      cityList: [],
    });
    const route = useRoute();
    const router = useRouter();
    const mode = ['month', 'month'];
    const isWatch = route.params.status === 'watch';
    const formRef = ref();

    const list = reactive({
      province: [],
      city: [],
    });
    const dynamicValidateForm = reactive({
      totalScoreMax: 0,
      intoPoolMin: 0,
      name: '',
      leadPools: '',
      timeValue: [],
      groupRuleList: [],
    });
    const required = {
      required: true,
      message: '不能为空',
    };

    const getConditionValue = value => {
      let arr = value.split(',');
      let arr$1 = [];
      state.tagTreeList.forEach(tagList => {
        tagList.children.forEach(tag => {
          if (arr.includes(tag.value)) {
            arr$1.push(tag.title);
          }
        });
      });
      return arr$1.toString();
    };

    const getProvinceList = () => {
      return cluescoreApi.getCityList('', {
        hierarchy: 1,
      });
    };
    const getCityList = () => {
      return cluescoreApi.getCityList('', {
        hierarchy: 2,
      });
    };
    const getOperatorListMap = () => {
      return tagApi.getPropertyRule(state.code);
    };
    const getPropertyList = () => {
      return tagApi.getPropertySelector(state.code);
    };
    const getActionPropertyList = () => {
      return tagApi.getActionSelector(state.code);
    };
    const getTagTreeList = () => {
      return tagApi.getTagTreeList('', {});
    };
    const scoreDetail = () => {
      const scoreModelId = route.params.id;
      if (scoreModelId != 'add') {
        cluescoreApi.scroedetail('', { scoreModelId }).then(res => {
          res.leadScoreRuleList.forEach((item, index) => {
            if (item.actionType) {
              item.col = item.col + ',' + item.actionType;
            }
            if (['area'].includes(item.col)) {
              item.value = item.value.split(',');
            }
            dynamicValidateForm.groupRuleList.push({
              actionType: item.actionType,
              conditionId: index + 1,
              action: item.action,
              col: item.col,
              operator: item.operator,
              value: item.value,
              secondValue: item.secondValue,
              dataType: item.dataType,
              score: item.score,
              type: item.type,
              id: item.id,
            });
            let condition = dynamicValidateForm.groupRuleList[index];
            if (item.type === 'property' || item.type === 'tag') {
              const temp = state.propertyList.find(i => i.colCode === item.col);
              if (temp.colCode !== 'tag') {
                condition.operatorList = [
                  {
                    dataType: 'char',
                    symbol: '=',
                    symbolCode: 'char_equal',
                    symbolName: '等于',
                    valueCount: 1,
                    valueType: 'char',
                  },
                ];
              } else {
                condition.operatorList = [
                  {
                    dataType: 'char',
                    symbol: '%%',
                    symbolCode: 'char_contain',
                    symbolName: '包含',
                    valueCount: 1,
                    valueType: 'char',
                  },
                ];
              }
              condition.dataType = temp.dataType;
            }

            if (item.type === 'behavior') {
              state.behaviorCount++;
              const temp = state.actionPropertyList.find(
                i => i.colCode === item.col
              );
              condition.actionList = temp.action;
              condition.dataType = temp.dataType;
            } else {
              state.propertyCount++;
            }
            if (['tag', '标签'].includes(condition.col)) {
              if (isWatch) {
                condition.valueName = getConditionValue(condition.value);
              } else {
                condition.value = condition.value.split(',');
              }
            }
          });
        });
        cluescoreApi.detail('', scoreModelId).then(res => {
          if (res.timeValue == null) {
            setTime();
            res.timeValue = dynamicValidateForm.timeValue;
          } else {
            res.timeValue = res.timeValue.split('@@');
          }
          Object.assign(dynamicValidateForm, res);
        });
      } else {
        setTime();
      }
    };
    Promise.all([
      getProvinceList(),
      getCityList(),
      getOperatorListMap(),
      getPropertyList(),
      getActionPropertyList(),
      getTagTreeList(),
    ]).then(values => {
      list.province = values[0];
      list.city = values[1];
      state.operatorListMap = values[2];
      state.propertyList = [
        ...values[3].contact,
        {
          tableCode: 'contact',
          tableName: 'contact',
          colCode: 'tag',
          colName: '标签',
          dataType: 'char',
          actionType: null,
          action: null,
        },
      ];
      values[4].behavior.forEach(item => {
        if (item.actionType) {
          item.colCode = item.colCode + ',' + item.actionType;
          item.col = item.colCode + ',' + item.actionType;
        }
      });
      state.actionPropertyList = values[4].behavior;
      state.tagTreeList = values[5]?.map(item => {
        let title = item.typeName;
        let key = item.typeName;
        let value = item.typeName;
        let children = item.tagList?.map(child => {
          return {
            key: child.id,
            title: child.tagName,
            value: child.tagCode,
          };
        });
        return {
          title,
          key,
          value,
          children,
        };
      });
      scoreDetail();
    });

    const handleChangeAction = (value, rule) => {
      rule.operator = '';
      rule.value = '';
      rule.secondValue = '';
      const temp = state.actionPropertyList.find(i => i.colCode === value);
      rule.actionList = temp.action;
      rule.dataType = temp.dataType;
    };
    const handleChangeProperty = (value, rule) => {
      rule.operator = '';
      rule.value = null;
      rule.secondValue = '';
      const temp = state.propertyList.find(i => i.colCode === value);
      if (temp.colCode !== 'tag') {
        rule.operatorList = [
          {
            dataType: 'char',
            symbol: '=',
            symbolCode: 'char_equal',
            symbolName: '等于',
            valueCount: 1,
            valueType: 'char',
          },
        ];
      } else {
        rule.type = 'tag';
        rule.operatorList = [
          {
            dataType: 'char',
            symbol: '%%',
            symbolCode: 'char_contain',
            symbolName: '包含',
            valueCount: 1,
            valueType: 'char',
          },
        ];
      }

      rule.dataType = temp.dataType;
    };
    const addGroupRule = type => {
      const index = dynamicValidateForm.groupRuleList.length - 1;
      if (type == 'behavior') {
        dynamicValidateForm.groupRuleList.push({
          conditionId: index + 1,
          action: null,
          col: null,
          operator: null,
          value: null,
          dataType: 'behavior',
          score: '',
          type,
        });
      } else {
        dynamicValidateForm.groupRuleList.push({
          conditionId: index + 1,
          action: null,
          col: null,
          operator: null,
          value: null,
          secondValue: '',
          dataType: 'char',
          score: '',
          type,
        });
      }
    };
    const handleChange = val => {
      dynamicValidateForm.timeValue[0] = val[0].format('YYYY-MM');
      dynamicValidateForm.timeValue[1] = val[1].format('YYYY-MM');
    };
    const handlePanelChange = (val, mode) => {
      dynamicValidateForm.timeValue[0] = val[0].format('YYYY-MM');
      dynamicValidateForm.timeValue[1] = val[1].format('YYYY-MM');
      mode.value = [
        mode[0] === 'date' ? 'month' : mode[0],
        mode[1] === 'date' ? 'month' : mode[1],
      ];
    };
    const getTotal = () => {
      let count = 0;
      dynamicValidateForm.groupRuleList.forEach(item => {
        count += item.score;
      });
      dynamicValidateForm.totalScoreMax = count;
    };
    const setTime = () => {
      const endTime = moment(new Date(new Date().getTime()))
        .format('YYYY-MM')
        .toString();
      const dates = new Date();
      dates.setMonth(dates.getMonth() - 2);
      var pastMonth = dates.getMonth() + 1;
      if (pastMonth >= 1 && pastMonth <= 9) {
        pastMonth = '0' + pastMonth;
      }
      const startTime = dates.getFullYear() + '-' + pastMonth;
      dynamicValidateForm.timeValue = [startTime, endTime];
    };
    const validate = (leadScoreModel, leadScoreRules) => {
      let isValid = true;
      if (leadScoreModel.name == '' || leadScoreModel.name == null) {
        message.error('模型名称不得为空');
        isValid = false;
        return isValid;
      }
      if (leadScoreRules.length == 0) {
        message.error('条件不得为空');
        isValid = false;
        return isValid;
      }

      leadScoreRules.every(item => {
        if (item.score == '' || item.score == null) {
          message.error('规则分数不得为空');
          isValid = false;
          return isValid;
        }
      });
      return isValid;
    };
    const save = () => {
      formRef.value
        .validateFields()
        .then(() => {
          const Id = route.params.id;
          let leadScoreModel = Object.assign({}, dynamicValidateForm);
          let leadScoreRules = dynamicValidateForm.groupRuleList.map(i => {
            let rule = { ...i };
            if (i.col.indexOf(',')) {
              rule.col = i.col.split(',')[0];
              rule.actionType = i.col.split(',')[1];
            }
            rule.value = rule.value.toString();
            return rule;
          });
          leadScoreModel.timeValue = leadScoreModel.timeValue.join('@@');
          const isValidate = validate(leadScoreModel, leadScoreRules);
          if (!isValidate) return;
          if (Id != 'add') {
            dynamicValidateForm.id = route.params.id;
            cluescoreApi
              .savedetail('', { leadScoreModel, leadScoreRules })
              .then(() => {
                router.go(-1);
              });
          } else {
            cluescoreApi
              .adddetail('', { leadScoreModel, leadScoreRules })
              .then(() => {
                router.go(-1);
              });
          }
        })
        .catch(e => {
          console.log(e);
        });
    };
    const removeRule = index => {
      dynamicValidateForm.groupRuleList.splice(index, 1);
      getTotal();
    };
    dictionaryApi.getItemTree('', { code: 'CHINA_AREA' }).then(data => {
      state.cityList = data;
    });
    return {
      SHOW_CHILD,
      ...toRefs(state),
      isWatch,
      required,
      formRef,
      mode,
      dynamicValidateForm,
      list,
      handleChangeProperty,
      handleChangeAction,
      addGroupRule,
      handleChange,
      handlePanelChange,
      getTotal,
      scoreDetail,
      save,
      removeRule,
      setTime,
      validate,
      handleChangeSelectCol(condition) {
        condition.col === '标签' && (condition.type = 'tag');
        condition.value = null;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  padding: 0;
  .center-box {
    height: calc(100vh - 176px);
    overflow-y: auto;
    padding: 20px;
  }
}
/deep/.ant-card-head {
  border: none;
}
.condition-table {
  width: 100%;
}

.condition-table td {
  padding: 0px 10px 20px 5px;
}
.condition-table .ant-select {
  width: 100%;
}
.remove-btn {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  color: #fff;
  text-align: center;
  background-color: rgb(253, 0, 0);
  border-radius: 100%;
  margin-left: 5px;
  font-size: 12px;
  cursor: pointer;
}
.remove-btn:before {
  content: '✕';
}
.add-btn {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  background-color: #adc3ff;
  border-radius: 100%;
  margin-left: 5px;
  font-size: 24px;
  cursor: pointer;
}
.add-btn:before {
  content: '+';
}
.ta-right {
  padding: 15px;
  padding-bottom: 0;
  border-top: 1px solid #f0f0f0;
}
.mast {
  color: red;
}
.hideRequiredMark :deep(.ant-form-item-label) {
  label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
  }
}
</style>
